<template>
    <div class="mt-4">
        <b-alert variant="success" show>Sodalizio inserito correttamente</b-alert>

        <b-card>
            <b-card-text>
                <p>
                    <strong>Per le società:</strong>
                    Una volta che verrà approvata la tua affiliazione riceverai un messaggio dal tuo Comitato.<br>
                    <strong>Grazie per esserti affiliato con ACSI!</strong>
                </p>
            </b-card-text>
        </b-card>

        <div class="mt-4">&nbsp;</div>

        <b-card-group deck>
            <b-card v-if="$store.getters.currentUser.usertype !== 'Sodalizio'">
                <b-card-text>Gestisci il sodalizio</b-card-text>
                <b-button @click.prevent="$router.push('/sportingclub/gestione/' + $route.params.id)" href="#" variant="info"><i class="fas fa-tasks"></i> Vai alla gestione sodalizio</b-button>
            </b-card>

            <b-card header-tag="header" footer-tag="footer">
                <b-card-text>Scarica e stampa la domanda di affiliazione</b-card-text>
                <b-button :href="getApiUrl() + '/sportingclub/affiliationrequest/' + $route.params.id + '?token=' + token" target="_blank" variant="success"><i class="far fa-file-pdf"></i> Scarica la domanda di affiliazione</b-button>
            </b-card>
        </b-card-group>
    </div>
</template>

<script>
 import ApiService from "@/core/services/api.service";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
 import JwtService from "@/core/services/jwt.service";

 export default {
     data() {
         return {
             sodalizio: {},
             loaded: false,
         };
     },

     created() {
         let url = '/sportingclub/manage/' + this.$route.params.id;
         ApiService.query(url)
                   .then((response) => {
                       this.sodalizio = response.data;
                       this.loaded = true;
                   })
                   .catch((error) => {
                       console.log(error);
                   })
     },

     computed: {
         token() {
             return JwtService.getToken();
         },
     },

     components: {
     },

     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Affiliazioni", route: "/sportingclub/review" },
             { title: "Riepilogo Sodalizio" }
         ]);
     },

     methods: {
         getApiUrl() {
             return ApiService.getApiUrl();
         },
     }
 };
</script>
